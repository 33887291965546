<template lang="pug">
v-form.form.mb-8(
  @submit.prevent='submitGitRegexp'
  ref='gitForm')
  span {{ $lang.usedNow }} {{ regexpDescription }} {{ currentRegexp }}
  v-row.flex-column(no-gutters)
    v-radio-group(
      v-model='selectedRegexp'
      row
      hide-details
      @change='togglePattern')
      v-radio(
        value='1'
        :label='$lang.prefixSearch')
      v-radio(
        value='2'
        :label='$lang.nameSearch')
    v-text-field.git__input.mr-1(
      label='Git Regexp'
      v-model='gitRegexp'
      :hint='$lang.hintAutoSearch'
      persistent-hint)
    v-select.git__select.pt-6(
      v-if='!listOfBranchesERR'
      :label='$lang.branchesToTrack'
      v-model='selectedBranches'
      :items='branches'
      multiple)
    .d-flex.mt-4
      button.setting__button-blue.mr-4(type='submit') {{ $lang.save }}
      v-btn.setting__button-white(
        @click.native.stop='clearForm'
        depressed) {{ $lang.clearForm }}
</template>

<script>
import { diff } from '@/helpers/arrayDiff'
import { createNamespacedHelpers } from 'vuex'
const { mapState: gitMapState } = createNamespacedHelpers('git')

export default {
  name: 'GitForm',
  props: {
    addGitRegexp: {
      type: Function,
    },
    isCleanOut: {
      type: Boolean,
      default: false,
    },
    git: {
      type: Object,
      default: () => {},
    },
    branches: {
      type: Array,
    },
  },
  data: () => ({
    gitRegexp: '',
    selectedRegexp: '',
    repo: '',
    prefixRegexp: '^\\[(?P<card_number>\\d+)\\]',
    nameRegexp:
      '\\[(?P<task_type>.+)\\][\\s-]+(?P<card_number>\\d+)[\\s-]+(?P<text>.+)',
    regexpDescription: '',
    selectedBranches: [],
  }),
  computed: {
    ...gitMapState(['listOfReposBranches', 'listOfBranchesERR']),
    currentRegexp() {
      return this.git.commit_regexp || null
    },
  },
  watch: {
    git() {
      this.checkCurrentGitRegexp()
    },
    listOfReposBranches() {
      this.setSelectedBranches()
    },
  },
  created() {
    this.checkCurrentGitRegexp()
    this.setSelectedBranches()
  },
  methods: {
    submitGitRegexp() {
      let formData = {
        git_url: this.git.git_url,
        commit_regexp: this.gitRegexp,
        board_id: this.$route.params.id,
        git_project_id: this.git.git_project_id,
        git_service: this.git.git_service,
      }

      if (!diff(this.selectedBranches, this.git.tracked_branches)) {
        formData.tracked_branches = this.selectedBranches
      }

      this.addGitRegexp(formData)
      this.clearForm()
    },
    togglePattern() {
      if (this.selectedRegexp === '1') {
        this.gitRegexp = this.prefixRegexp
      } else {
        this.gitRegexp = this.nameRegexp
      }
    },
    clearForm() {
      this.gitRegexp = ''
      this.selectedRegexp = ''
      this.selectedBranches = []
    },
    checkCurrentGitRegexp() {
      if (this.currentRegexp === this.prefixRegexp) {
        this.selectedRegexp = '1'
        this.regexpDescription = this.$lang.prefixSearch
      } else if (this.currentRegexp === this.nameRegexp) {
        this.selectedRegexp = '2'
        this.regexpDescription = this.$lang.nameSearch
      } else {
        this.regexpDescription = this.$lang.autoSearch
      }
    },
    setSelectedBranches() {
      this.selectedBranches = this.git.tracked_branches.length
        ? this.git.tracked_branches
        : this.branches[0]
        ? [this.branches[0]]
        : []
    },
  },
}
</script>

<style lang="scss" scoped>
.git__select::v-deep .v-select__selections {
  padding-left: 21px;
}
</style>
